// New Code
import Axios from "../../../utils/service";

export const fetchEvents = async (data) => {
  const {
    query: { cabinClass, legs, travelerCount },
  } = data;

  let fromCountry = legs[0].origin.city.split(",").map((fCity) => {
    return fCity.trim();
  });
  let toCountry = legs[0].destination.city.split(",").map((tCity) => {
    return tCity.trim();
  });

  let fromAirport = legs[0].origin.iataCode;
  let toAirport = legs[0].destination.iataCode;
  let departureDate = FormatDate(new Date(legs[0].departureDate));
  let returnDate =
    legs[0].returnDate !== undefined
      ? FormatDate(new Date(legs[0].returnDate))
      : "undefined";

  let Hitit = true; //To include Travelport & Hitit
  // let Hitit = false; //To exclude Travelport & Hitit

  if (Hitit) {
    try {
      let res = "";
      let resAir = "";
      let resSial = "";
      let resPromise = "";
      let resAirPromise = "";
      let resSialPromise = "";
      let api_type = "one_way_trip";
      let count = 0;

      if (typeof legs[0].returnDate !== "undefined") {
        resPromise = new Promise((resolve,reject) => {
          Axios.get("api/round-trip-search", {
            params: {
              ticket_class: cabinClass.label,
              from_date: departureDate,
              from: fromAirport,
              to: toAirport,
              ummrah: travelerCount.numUmmrah,
              adult: travelerCount.numAdult,
              infant: travelerCount.numInfant,
              children: travelerCount.numChild,
              to_date: returnDate ? returnDate : "",
            },
          }).then((response)=>{
            resolve(response);
          }).catch((error)=>{
            reject(error);
          })
        })

        resAirPromise = new Promise((resolve,reject) => {
          Axios.get("api/round-trip-search-airblue", {
            params: {
              ticket_class: cabinClass.label,
              from_date: departureDate,
              from: fromAirport,
              to: toAirport,
              adult: travelerCount.numAdult,
              infant: travelerCount.numInfant,
              children: travelerCount.numChild,
              to_date: returnDate ? returnDate : "",
            },
          }).then((response)=>{
            resolve(response);
          }).catch((error)=>{
            reject(error);
          })
        })
        if (fromCountry[1] === "Pakistan" && toCountry[1] === "Pakistan") {
          resSialPromise = new Promise((resolve,reject) => {
            Axios.get("api/round-trip-search-airsial", {
              params: {
                ticket_class: cabinClass.label,
                from_date: departureDate,
                from: fromAirport,
                to: toAirport,
                adult: travelerCount.numAdult,
                infant: travelerCount.numInfant,
                children: travelerCount.numChild,
                to_date: returnDate ? returnDate : "",
              },
            }).then((response)=>{
              resolve(response);
            }).catch((error)=>{
              reject(error);
            })
          })
        }
        console.log("api resoponse", res, resAir, resSial)
      } else {
        resPromise = new Promise((resolve,reject) => {
          Axios.get("api/one-way-search", {
            params: {
              ticket_class: cabinClass.label,
              from_date: departureDate,
              from: fromAirport,
              to: toAirport,
              adult: travelerCount.numAdult,
              infant: travelerCount.numInfant,
              children: travelerCount.numChild,
            },
          }).then((response)=>{
            resolve(response);
          }).catch((error)=>{
            reject(error);
          })
        })
        resAirPromise = new Promise((resolve,reject) => {
          Axios.get("api/one-way-search-airblue", {
            params: {
              ticket_class: cabinClass.label,
              from_date: departureDate,
              from: fromAirport,
              to: toAirport,
              adult: travelerCount.numAdult,
              infant: travelerCount.numInfant,
              children: travelerCount.numChild,
            },
          }).then((response)=>{
            resolve(response);
          }).catch((error)=>{
            reject(error);
          })
        })
        if (fromCountry[1] === "Pakistan" && toCountry[1] === "Pakistan") {
          resSialPromise = new Promise((resolve,reject) => {
            Axios.get("api/one-way-search-airsial", {
              params: {
                ticket_class: cabinClass.label,
                from_date: departureDate,
                from: fromAirport,
                to: toAirport,
                adult: travelerCount.numAdult,
                infant: travelerCount.numInfant,
                children: travelerCount.numChild,
                to_date: returnDate ? returnDate : "",
              },
            }).then((response)=>{
              resolve(response);
            }).catch((error)=>{
              reject(error);
            })
          })
        }
        console.log("api resoponse >>>", resPromise, resAirPromise, resSialPromise)
      }
      console.log("api resopons::",resPromise, resAirPromise, resSialPromise)

      return Promise.all([resPromise, resAirPromise, resSialPromise])
      .then(([res, resAir, resSial]) => {
        // Your conditions and logic here
        console.log("resSialPromise", resSialPromise, resSial)
        if (!res["data"].result.flights) {
          res["data"].result.flights = [];
          res["data"].result.api_type = api_type;
          count++;
        }
  
        if (resAir["data"].status === "200" && resAir["data"].result.flights) {
          resAir["data"].result.flights.forEach((airblue) => {
            res["data"].result.flights.push(airblue);
          });
          if (count > 0) {
            res["data"].status = "200";
            res["data"].result.flight_type = resAir["data"].result.flight_type;
            console.log("count::", res["data"], res["data"].result.flight_type , res["data"].status)
          }
        }

        console.log("stattus::", res["data"], resSialPromise, resSial["data"])
        if (
          resSial !== "" &&
          resSial["data"].status === "200" &&
          res["data"].result.flights &&
          fromCountry[1] === "Pakistan" &&
          toCountry[1] === "Pakistan"
        ) {
          resSial["data"].result.flights.map((airsial) => {
            res["data"].result.flights.push(airsial);
            return 0;
          });
          if (count > 0) {
            res["data"].status = "200";
            res["data"].result.flight_type = resSial["data"].result.flight_type;
          }
        }
  
        // Continue with your logic based on the combined results
        return res["data"];
      }).catch((error) => {
        // Handle errors here
        console.error(error);
      });

    } catch (error) {
      return error;
    }
  }
  // Code Separated Due to CORS issue on Local
  else {
    try {
      let resAir = "";
      let resSial = "";
      let resAirPromise = "";
      let resSialPromise = "";

      if (typeof legs[0].returnDate !== "undefined") {
        resAirPromise = new Promise((resolve,reject) => {
          Axios.get("api/round-trip-search-airblue", {
            params: {
              ticket_class: cabinClass.label,
              from_date: departureDate,
              from: fromAirport,
              to: toAirport,
              adult: travelerCount.numAdult,
              infant: travelerCount.numInfant,
              children: travelerCount.numChild,
              to_date: returnDate ? returnDate : "",
            },
          }).then((response)=>{
            resolve(response);
          }).catch((error)=>{
            reject(error);
          })
        })
        resSialPromise = new Promise((resolve,reject) => {
          Axios.get("api/round-trip-search-airsial", {
            params: {
              ticket_class: cabinClass.label,
              from_date: departureDate,
              from: fromAirport,
              to: toAirport,
              adult: travelerCount.numAdult,
              infant: travelerCount.numInfant,
              children: travelerCount.numChild,
              to_date: returnDate ? returnDate : "",
            },
          }).then((response)=>{
            resolve(response);
          }).catch((error)=>{
            reject(error);
          })
        })
      } else {
        resAirPromise = new Promise((resolve,reject) => {
          Axios.get("api/one-way-search-airblue", {
            params: {
              ticket_class: cabinClass.label,
              from_date: departureDate,
              from: fromAirport,
              to: toAirport,
              adult: travelerCount.numAdult,
              infant: travelerCount.numInfant,
              children: travelerCount.numChild,
            },
          }).then((response)=>{
            resolve(response);
          }).catch((error)=>{
            reject(error);
          })
        })
        resSialPromise = new Promise((resolve,reject) => {
          Axios.get("api/one-way-search-airsial", {
            params: {
              ticket_class: cabinClass.label,
              from_date: departureDate,
              from: fromAirport,
              to: toAirport,
              adult: travelerCount.numAdult,
              infant: travelerCount.numInfant,
              children: travelerCount.numChild,
              to_date: returnDate ? returnDate : "",
            },
          }).then((response)=>{
            resolve(response);
          }).catch((error)=>{
            reject(error);
          })
        })
      }


      // return resAir["data"];

      return Promise.all([resAirPromise, resSialPromise])
      .then(([resAir, resSial]) => {
        // Your conditions and logic here
        if (resSial["data"].status === "200") {
          resSial["data"].result.flights.map((airsial) => {
            resAir["data"].result.flights.push(airsial);
            return 0;
          });
        }
  
        // Continue with your logic based on the combined results
        return resAir["data"];
      }).catch((error) => {
        // Handle errors here
        console.error(error);
      });
    } catch (error) {
      return error;
    }
  }
};

function FormatDate(date) {
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  const month = new Intl.DateTimeFormat("en", { month: "numeric" }).format(
    date
  );
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  return `${year}-${month}-${day}`;
}