/*
 *
 * FlightList constants
 *
 */

export const REQUEST_API_DATA = 'app/FlightList/REQUEST_API_DATA';
export const RECEIVE_API_DATA = 'app/FlightList/RECEIVE_API_DATA';
export const FLIGHT_REQUEST_FAILED = 'app/FlightList/FLIGHT_REQUEST_FAILED';
export const API_DATA_LOADER = 'app/FlightList/API_DATA_LOADER';
export const UPDATE_QUERY = 'app/FlightList/UPDATE_QUERY';
